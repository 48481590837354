import React from 'react'
import SEOTools from '../components/SEOTools'
import { Col, Container, Row } from 'react-bootstrap'
import FaqItem from '../components/FaqItem'
import { graphql } from 'gatsby'
import HeroUnit from '../components/HeroUnit'
import { faFileInvoiceDollar, faGlobe, faQuestionCircle, faUserShield } from '@fortawesome/free-solid-svg-icons'
import ContactForm from '../components/Forms/ContactForm'
import FaqCategoryScrollButton from '../components/FaqCategoryScrollButton'
import { getSrc } from 'gatsby-plugin-image'
import StyledSideCol from '../components/styled/StyledSideCol'
import Layout from '../components/Layout'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const FaqPage = ({ data }) => {
    // Grab faq items from queried data
    const faqItems = data.faqItems.nodes
    // Split items based on category
    const top = faqItems.filter(item => item.category.includes('topQuestions'))
    const general = faqItems.filter(item => item.category.includes('general'))
    const invoices = faqItems.filter(item => item.category.includes('invoices'))
    const security = faqItems.filter(item => item.category.includes('security'))
    const transactions = faqItems.filter(item => item.category.includes('transactions'))

    return (
        <Layout>
            <SEOTools
                title='Kundservice'
                description='Här hittar du svar på de vanligaste frågorna om Siru Mobile. Om du inte hittar det du letar efter, kontakta gärna vår kundtjänst så hjälper vi till.'
                image={getSrc(data.heroBg.childImageSharp)}>
                {/* eslint-disable-next-line */}
                <html {...schemaAttributes} />
            </SEOTools>

            <HeroUnit backgroundImage={data.heroBg}>
                <Row className='d-flex align-items-center justify-content-center text-center text-white my-5'>
                    <Col md='8'>
                        <h1>Söker du hjälp? Då har du kommit rätt.</h1>
                    </Col>
                </Row>
            </HeroUnit>

            <section className='pt-2 mb-5'>
                <Container>
                    <Row className='my-5'>
                        <StyledSideCol md='3' className='mb-3'>
                            <div className='text-secondary top'>Vårt bolag</div>
                            <div className='h3 bottom'>
                                <strong>Siru Mobile</strong>
                            </div>
                        </StyledSideCol>
                        <Col>
                            <p>
                                Välkommen till vår FAQ sida! Här hittar du svar på de vanligaste frågorna om Siru
                                Mobile. Om du inte hittar det du letar efter, kontakta gärna vår kundtjänst så hjälper
                                vi till.
                            </p>
                        </Col>
                    </Row>

                    <Row className='text-center mb-5'>
                        <FaqCategoryScrollButton icon={faQuestionCircle} text='Allmänna frågor' scrollToId='general' />
                        <FaqCategoryScrollButton icon={faGlobe} text='Transaktioner' scrollToId='transactions' />
                        <FaqCategoryScrollButton icon={faFileInvoiceDollar} text='Fakturering' scrollToId='invoices' />
                        <FaqCategoryScrollButton icon={faUserShield} text='Säkerhet' scrollToId='security' />
                    </Row>

                    <h2 className='mb-5'>Populära frågor</h2>

                    {top.map(item => (
                        <FaqItem
                            key={item.slug.current}
                            question={item.question}
                            answer={item._rawAnswer}
                            slug={item.slug.current}
                        />
                    ))}

                    <h2 id='general' className='my-5 pt-4'>
                        Allmänna frågor
                    </h2>

                    {general.map(item => (
                        <FaqItem
                            key={item.slug.current}
                            question={item.question}
                            answer={item._rawAnswer}
                            slug={item.slug.current}
                        />
                    ))}

                    <h2 id='transactions' className='my-5 pt-4'>
                        Transaktioner & begränsningar
                    </h2>

                    {transactions.map(item => (
                        <FaqItem
                            key={item.slug.current}
                            question={item.question}
                            answer={item._rawAnswer}
                            slug={item.slug.current}
                        />
                    ))}

                    <h2 id='invoices' className='my-5 pt-4'>
                        Fakturering
                    </h2>

                    {invoices.map(item => (
                        <FaqItem
                            key={item.slug.current}
                            question={item.question}
                            answer={item._rawAnswer}
                            slug={item.slug.current}
                        />
                    ))}

                    <h2 id='security' className='my-5 pt-4'>
                        Säkerhet
                    </h2>

                    {security.map(item => (
                        <FaqItem
                            key={item.slug.current}
                            question={item.question}
                            answer={item._rawAnswer}
                            slug={item.slug.current}
                        />
                    ))}

                    <Row className='mt-5 mb-4 pt-5'>
                        <StyledSideCol md='3' className='mb-3'>
                            <div className='text-secondary top'>Kontakta oss</div>
                            <div className='h3 bottom'>
                                <strong>Ställ en fråga</strong>
                            </div>
                        </StyledSideCol>
                        <Col>
                            <p>
                                Har du frågor eller feedback angående Siru Mobile? Du är varmt välkommen att kontakta
                                oss genom att fylla i formuläret.
                            </p>
                        </Col>
                    </Row>

                    <ContactForm name='contact' />
                </Container>
            </section>
        </Layout>
    )
}

/**
 * Page query can be dynamic with variables. Can only be run on a page level.
 */
export const query = graphql`
    query ($langKey: String) {
        # Get hero image
        heroBg: file(relativePath: { eq: "supportive-woman.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
        # Get faq items
        faqItems: allSanityFaqItem(filter: { i18n_lang: { eq: $langKey } }, sort: { fields: weight, order: ASC }) {
            nodes {
                slug {
                    current
                }
                question
                category
                _rawAnswer(resolveReferences: { maxDepth: 1 })
            }
        }
    }
`

const schemaAttributes = { itemScope: true, itemType: 'https://schema.org/FAQPage' }

export default FaqPage
